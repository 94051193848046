import User from "models/user";
import { ISO_DATE } from "lib/formats";
import { formats } from "config";

// TODO: come up with something better
const patchDate = (date) => {
  date.toString = function () {
    return this.format(ISO_DATE);
  };
};

export const fromAbout = (about) => {
  let {
    user_id,
    user_site,
    birthdate,
    citizenship = "",
    ethnicity = "",
    goverment_id: governmentId = "",
    ...rest
  } = about;

  let user = User.fromAPI(
    {
      id: user_id,
      default_site: user_site,
      ethnicity: about.ethnicity,
      dob: moment(birthdate, formats.dob).format(ISO_DATE),
      full_name: `${about.preferred_name || about.first_name} ${
        about.last_name
      }`,
      ...rest,
    },
    { allowMissingId: true }
  );

  user.singapore = {
    citizenship,
    governmentId,
    ethnicity
  };

  patchDate(user.bornAt);
  return user;
};

export const toAbout = (user) => {
  user.bornAt = moment(user.bornAt);
  patchDate(user.bornAt);

  let {
    citizenship,
    governmentId: goverment_id, // TODO: get rid of typo
    ethnicity: singaporeEthnicity
  } = user.singapore;

  const isSingapore = ['amk', 'inn'].includes(user.defaultSite.code)

  return {
    ...user.toJSON(),
    skip_insurance: true,
    user_id: user.id,
    user_site: user.defaultSite.code,
    birthdate: user.dob,
    ethnicity: isSingapore ? singaporeEthnicity : user.ethnicity,
    citizenship,
    goverment_id
  };
};
