import checkInStore from "stores/new-check-in";
import { languageOptions } from "components/elements/language-select";
import Contact from "./contact";
import ReadOnly from "./read-only";
import CheckInFooter from "../check-in-footer";
import {
  Form,
  Button,
  Input,
  Select,
  CheckBox,
  TextArea,
  Radio,
  Spinner,
} from "components/elements";
import { formatUSPhone, getGenderLabel } from "lib/utils";
import {
  pronounsMappingArray,
  genderIdentityMappingArray,
  sexualOrientationMappingArray,
  raceMappingArray,
  ethnicityMappingArray,
  singaporeEthnicityMappingArray
} from "lib/utils/sogi";
import { map, isEmpty } from "lodash";
import { observer } from "mobx-react";
import Step from "components/mixins/Step";
import { insuranceTypeMapping, states, client } from "config";
import { toAbout } from "../helpers";
import TextUpdatesModal from "components/modals/TextUpdatesModal";
import SogiInfoPopUp from "components/profile/SOGIInfoPopUp";
import oldAppStore from "stores/app";
import oldCheckInStore from "stores/checkin";
const authStore = require("stores/new-auth");
const { showModal } = require("components/elements/new-modal");

const reName = /^[a-zA-Z][-\w .']*$/.source;
const maybeFormatPhone = ({ target }) => {
  let { site } = checkInStore;
  if (!site.isUS) return;

  let { value } = target;
  if (!value && !target.required) {
    target.setCustomValidity("");
    return;
  }

  let pretty = formatUSPhone(value);
  if (pretty) {
    target.setCustomValidity("");
    target.value = pretty;
  } else {
    target.setCustomValidity("Incorrect US phone format");
    target.checkValidity();
  }
};

let showSogiInfoPopUp = (evt) => {
  showModal(<SogiInfoPopUp rect={evt.target.getBoundingClientRect()}  isCtm={false} hideNameUsed={false} hasRaceAndEthnicity showSexualOrientation/>);
};

const renderState = (state) => {
  return <option key={state}>{state}</option>;
};

const renderInsuranceBlock = (
  { label = "Insurance", ...fields },
  insuranceKey
) => {
  if (fields.disabled) return;

  const renderInsuranceField = (fieldLabel, fieldKey) => {
    let name = `insurances.${insuranceTypeMapping[insuranceKey]}_${fieldKey}`;
    let ariaLabel = `${label}: ${fieldLabel}`;

    return (
      <label key={fieldKey} className="AboutStep-field">
        {fieldLabel}
        {fieldKey == "notes" ? (
          <TextArea
            name={name}
            className="AboutStep-notes"
            aria-label={ariaLabel}
          />
        ) : (
          <Input name={name} aria-label={ariaLabel} />
        )}
      </label>
    );
  };

  return (
    <section key={insuranceKey} className="AboutStep-insuranceBlock">
      <h3 className="AboutStep-insuranceHeader">{label}</h3>
      {map(fields, renderInsuranceField)}
    </section>
  );
};

const showTextUpdatesModal = () => {
  oldAppStore.actions.showModal({
    title: "Text Reminders - Terms of Service",
    body: TextUpdatesModal(),
  });
};

const AboutStep = React.createClass({
  displayName: "AboutStep",
  mixins: [Step],

  setErrors() {
    const {
      checkin: { about },
    } = this.state;
    const { site } = checkInStore;
    let requiredFields;

    const required = ["shortGender", "address", "city", "state", "cellPhone", "emergencyContact.name", "emergencyContact.relationship", "emergencyContact.phone"];
    const requiredRaceAndEthnicity = ["shortGender", "address", "city", "state", "cellPhone", "emergencyContact.name", "emergencyContact.relationship", "emergencyContact.phone", "race", "ethnicity", "singapore.ethnicity"];

    if (about.can_see_real) {
      if (site.isCork || site.isSingapore) {
        requiredFields = requiredRaceAndEthnicity
      } else {
        requiredFields = requiredRaceAndEthnicity.concat('zip')
      }
    } else {
      if (site.isCork || site.isSingapore) {
        requiredFields = required

      } else {
        requiredFields = required.concat(['zip'])
      }
    }
    requiredFields.forEach(field => {
      if (document.getElementById(field) && !document.getElementById(field).value && !this.state.errors.includes(field)) {
        this.setState({ errors: this.state.errors.concat(field) });
      }
    });
  },

  componentDidUpdate() {
    this.setErrors();
  },

  async afterSubmit() {
    this.setErrors();

    if (!this.state.errors.length) {
      let { user } = checkInStore;
      await oldCheckInStore.actions.update({
        ...this.getStepData(),
        about: toAbout(user),
      });

      if (user.id) {
        await user.saveInsurance();
      }

      checkInStore.getStep().next.activate();
    }
  },

  errorStyles(name) {
    if (this.state.errors.includes(name)) {
      return "Error";
    } else {
      return "";
    }
  },

  validateRequired(event) {
    const isPhone = (event.target.name === "emergencyContact.phone") || (event.target.name === "cellPhone");
    if (event.target.value === "" && !this.state.errors.includes(event.target.name)) {
      this.setState({ errors: this.state.errors.concat(event.target.name) });
      if (isPhone) { maybeFormatPhone(event) };
    } else if (event.target.value !== "" && this.state.errors.includes(event.target.name)) {
      this.setState({ errors: this.state.errors.filter(error => error !== event.target.name) });
      if (isPhone) { maybeFormatPhone(event) };
    } else {
      if (isPhone) { maybeFormatPhone(event) };
    }
  },

  renderPrettyDOB(patient) {
    return moment(patient.born_at).format("MMM. D, YYYY");
  },

  renderPersonalInfo() {
    const {
      checkin: { about },
    } = this.state;
    const {user} = authStore;
    const isAdultWithSOGI = about.can_see_sogi;
    const showRaceAndEthnicity = about.can_see_real;
    const isCork = user.default_site == "crk"
    const isSingapore = ['amk', 'inn'].includes(user.defaultSite.code)
    const genderLabel = getGenderLabel(client, isAdultWithSOGI, isCork, isSingapore);

    return (
      <section className="AboutStep-block">
        <fieldset style={{border: 'none', margin: 'none', padding: 'none'}}>
        <legend className="FieldHeading">
          Personal Information
          {this.renderSogiInfoPopUp()}
        </legend>
        <label className="AboutStep-field">
          Name Used - optional
          <Input name="preferredName" placeholder="Ex: Mike" autoFocus />
        </label>
        <ReadOnly name="firstName" label="First Name" text={about.first_name} />
        <label className="AboutStep-field">
          Middle Name - optional
          <Input name="middleName" placeholder="Ex: William" pattern={reName} />
        </label>
        <ReadOnly
          name="lastName"
          label="Last Name"
          text={about.last_name}
          required
        />
        {!!genderLabel && (
          <label className="AboutStep-field">
            {genderLabel}
            <span className="sr-only">(Required)</span>
            <Select className={this.errorStyles("shortGender")} name="shortGender" id="shortGender" aria-label="Gender (Required)" onBlur={(e) => this.validateRequired(e)} >
              <option value="" />
              <option value="M">Male</option>
              <option value="F">Female</option>
              {(isCork || isSingapore) ? (
              <option value="O">Other</option>
              ) : (
              <option value="I">Intersex</option>
              )}
            </Select>
          </label>
        )}
        <ReadOnly
          name="bornAt"
          label="Date of Birth"
          text={this.renderPrettyDOB(about)}
          required
        />
        <label className="AboutStep-field">
          Preferred Language - optional
          <Select name="preferredLanguage">
            <option />
            {languageOptions}
          </Select>
        </label>
        {isAdultWithSOGI && (
          <>
            <label className="AboutStep-field">
            Pronouns - optional
            <Select name="pronouns">
              <option />
              {pronounsMappingArray.map((pronoun, index) => (
                <option key={index} value={pronoun.value}>
                  {pronoun.label}
                </option>
              ))}
            </Select>
          </label>
          <label className="AboutStep-field">
            Gender Identity - optional
            <Select name="genderIdentity">
              <option />
              {genderIdentityMappingArray.map((gender_mapping, index) => (
                <option key={index} value={gender_mapping.value}>
                  {gender_mapping.label}
                </option>
              ))}
            </Select>
          </label>
          <label className="AboutStep-field">
            Sexual Orientation - optional
            <Select name="sexualOrientation">
              <option />
              {sexualOrientationMappingArray.map((sex_orientation, index) => (
                <option key={index} value={sex_orientation.value}>
                  {sex_orientation.label}
                </option>
              ))}
            </Select>
          </label>
        </>
        )}
        {showRaceAndEthnicity && (
          <>
            <label className="AboutStep-field">
              Race
              <Select className={this.errorStyles("race")} id="race" name="race" onBlur={(e) => this.validateRequired(e)} required>
                <option />
                {raceMappingArray.map((race, index) => (
                  <option key={index} value={race.value}>
                    {race.label}
                  </option>
                ))}
              </Select>
            </label>
            <label className="AboutStep-field">
              Ethnicity
              <Select className={this.errorStyles("ethnicity")} id="ethnicity" name="ethnicity" onBlur={(e) => this.validateRequired(e)} required>
                <option/>
                {
                  ethnicityMappingArray.map((ethnicity, index) =>(
                    <option key={index} value={ethnicity.value}>
                      {ethnicity.label}
                    </option>
                  ) )
                }
              </Select>
            </label>
          </>
        )}
        </fieldset>
      </section>
    );
  },

  renderSMSReminders() {
    if (!checkInStore.hasSMSReminders) return;

    return (
      <div className="AboutStep-sms">
        <CheckBox name="hasSMSReminders">Receive text reminders</CheckBox>
        <Button
          id="check-in-sms-reminders"
          className="AboutStep-terms"
          onClick={showTextUpdatesModal}
        >
          Terms and conditions
        </Button>
      </div>
    );
  },

  renderSogiInfoPopUp() {
    const {
      checkin: { about },
    } = this.state;
    if (!about.can_see_sogi) return;

    return (
      <span>
        <i className='fa fa-info-circle ProfileHeader-sogi-info-icon' id='profile-header-sogi-info-icon' role='button' aria-label='Sexual Orientation and Gender Identity Information' tabIndex='0' onClick={showSogiInfoPopUp} />
      </span>
    );
  },

  renderContactInfo() {
    let { site } = checkInStore;
    let requiredZip = !(site.isCork || site.isSingapore);

    return (
      <section className="AboutStep-block">
        <fieldset style={{border: 'none', margin: 'none', padding: 'none'}}>

        <legend className="FieldHeading">Contact Information</legend>
        <label className="AboutStep-field">
          Address
          <span className="sr-only">(Required)</span>
          <Input className={this.errorStyles("address")} name="address" id="address" placeholder="123 Main Street" aria-label="Address (Required)" onBlur={(e) => this.validateRequired(e)} required />
        </label>
        <div className="AboutStep-field">
          City, State, ZIP
          <span className="sr-only">(Required)</span>
          <span className="AboutStep-area">
            <Input
              className={`AboutStep-city ${this.errorStyles("city")}`}
              name="city"
              id="city"
              placeholder="Anytown"
              aria-label="City (Required)"
              onBlur={(e) => this.validateRequired(e)}
              required
            />
            {site.isUS ? (
              <Select
                className={`AboutStep-state ${this.errorStyles("state")}`}
                name="state"
                id="state"
                aria-label="State (Required)"
                onBlur={(e) => this.validateRequired(e)}
                required
              >
                <option value="" />
                {states.map(renderState)}
              </Select>
            ) : (
              <Input
                className={`AboutStep-state ${this.errorStyles("state")}`}
                name="state"
                id="state"
                placeholder="State"
                aria-label="State (Required)"
                onBlur={(e) => this.validateRequired(e)}
                required
              />
            )}
            <Input
              className={`AboutStep-zip ${this.errorStyles("zip")}`}
              name="zip"
              id="zip"
              placeholder="43434"
              aria-label="ZIP (Required)"
              onBlur={site.isUS ? (e) => this.validateRequired(e) : null}
              required={requiredZip}
            />
          </span>
        </div>
        <Contact className="AboutStep-field" name="email" label="Email" readOnly />
        <Contact
          className="AboutStep-field"
          name="homePhone"
          type="tel"
          label="Home Phone - optional"
          placeholder="(650) 555-1111"
          onBlur={maybeFormatPhone}
          required={false}
        />
        <Contact
          className="AboutStep-field"
          id="cellPhone"
          name="cellPhone"
          type="tel"
          label="Mobile Phone"
          placeholder="(650) 555-2222"
          onBlur={(e) => this.validateRequired(e)}
          required={true}
        />
        {this.renderSMSReminders()}
        <Contact
          className="AboutStep-field"
          name="workPhone"
          type="tel"
          label="Work Phone - optional"
          placeholder="(650) 555-3333"
          onBlur={maybeFormatPhone}
          required={false}
        />
        </fieldset>
      </section>
    );
  },

  renderEmergencyDetails() {
    return (
      <section className="AboutStep-block">
        <fieldset style={{border: 'none', margin: 'none', padding: 'none'}}>

        <legend className="FieldHeading">Emergency Contact</legend>
        <label className="AboutStep-field">
          Name
          <span className="sr-only">(Required)</span>
          <Input
            className={this.errorStyles("emergencyContact.name")}
            name="emergencyContact.name"
            id="emergencyContact.name"
            placeholder="Mary Jackson"
            aria-label="Emergency contact name (Required)"
            maxLength={50}
            onBlur={(e) => this.validateRequired(e)}
            required
          />
        </label>
        <label className="AboutStep-field">
          Relationship
          <span className="sr-only">(Required)</span>
          <Input
            className={this.errorStyles("emergencyContact.relationship")}
            name="emergencyContact.relationship"
            id="emergencyContact.relationship"
            placeholder="Neighbor"
            aria-label="Emergency contact relationship (Required)"
            maxLength={50}
            onBlur={(e) => this.validateRequired(e)}
            required
          />
        </label>
        <label className="AboutStep-field">
          Phone
          <span className="sr-only">(Required)</span>
          <Input
            className={this.errorStyles("emergencyContact.phone")}
            name="emergencyContact.phone"
            id="emergencyContact.phone"
            type="tel"
            placeholder="(650) 555-4444"
            aria-label="Emergency contact phone (Required)"
            onBlur={(e) => this.validateRequired(e)}
            required
          />
        </label>
        </fieldset>
      </section>
    );
  },

  renderInsuranceDetails() {
    let { insurances } = checkInStore.site;
    if (!isEmpty(insurances)) return map(insurances, renderInsuranceBlock);

    return (
      <React.Fragment>
        <label className="AboutStep-field">
          Plan
          <Input
            name="insurance.plan"
            placeholder="Aetna Plus"
            aria-label="Insurance Plan"
          />
        </label>
        <label className="AboutStep-field">
          Policy Holder
          <Select name="insurance.holder" aria-label="Insurance Policy Holder">
            <option />
            <option>Self</option>
            <option>Spouse</option>
            <option>Dependent</option>
          </Select>
        </label>
        <label className="AboutStep-field">
          Policy Number
          <Input
            name="insurance.id"
            placeholder="XCV3451324S"
            aria-label="Insurance Policy Number"
          />
        </label>
      </React.Fragment>
    );
  },

  renderOtherInfo() {
    let { site } = checkInStore;
    if (!site.isSingapore) return;

    return (
      <section className="AboutStep-block">
        <h3 className="FieldHeading">Other Information</h3>
        <div className="AboutStep-field">
          <label>Are you a citizen or resident of Singapore?</label>
          <Radio name="singapore.citizenship" value="SGP" required>
            Yes
          </Radio>
          <Radio name="singapore.citizenship" value="XXX" required>
            No
          </Radio>
        </div>
        <label className="AboutStep-field">
          Please enter your NRIC number, FIN number or Passport ID number
          <Input
            name="singapore.governmentId"
            placeholder="S1234567X"
            aria-label="Government ID"
            required
          />
        </label>
        <label className="AboutStep-field">
          Ethnicity
          <Select
            className={this.errorStyles("singapore.ethnicity")}
            id="singapore-ethnicity"
            name="singapore.ethnicity"
            onBlur={(e) => this.validateRequired(e)}
            required
          >
            <option/>
            {
              singaporeEthnicityMappingArray.map((ethnicity, index) =>(
                <option key={index} value={ethnicity.value}>
                  {ethnicity.label}
                </option>
              ) )
            }
          </Select>
        </label>
      </section>
    );
  },

  render() {
    let { user } = checkInStore;
    if (user.isFetchingInsurance) {
      return <Spinner className="AboutStep-spinner" />;
    }

    return (
      <Form
        id="about-form"
        className="AboutStep-form"
        dataSource={user}
        afterSubmit={this.afterSubmit}
      >
        <h2 className="SectionHeading">{checkInStore.getStep().title}</h2>
        {this.state.errors.length > 0 && (
          <div className="WarningBox-border-box">
            <i className="fa fa-exclamation-triangle WarningBox-orange-icon"></i>
            <span className="WarningBox-text">Required information is missing. Please update your record.</span>
          </div>
        )}
        {this.renderPersonalInfo()}
        {this.renderContactInfo()}
        {this.renderEmergencyDetails()}

        {user.can_see_insurance && (
          <section className="AboutStep-block">
            <fieldset style={{border: 'none', margin: 'none', padding: 'none'}}>
              <legend className="FieldHeading">Insurance Information</legend>
              {this.renderInsuranceDetails()}
            </fieldset>
          </section>
        )}
        {this.renderOtherInfo()}
        <CheckInFooter disableContinue={this.state.errors.length > 0} />
      </Form>
    );
  },
});

export default observer(AboutStep);
